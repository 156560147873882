import axios from 'axios'


export const login = async (sessionGuid?: string | null) => {
    const result = await axios.post(`${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3301'}/v1/auth`, {username:'slavakoshevoi', password: '1'}, {
        headers: {
            'API':1,
        }
    })
    localStorage.setItem('access_token', result.data.access_token)
    localStorage.setItem('refresh_token', result.data.refresh_token)

    const headers = {
        'Authorization': 'Bearer '+result.data.access_token,
        'API':1,
        'x-refresh': result.data.refresh_token,
    }

    if (!sessionGuid) {
        const session = {
            scene_guid: localStorage.getItem('scene'),
        }


        const {data} = await axios.post(`${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3301'}/session/create/url`, session, {
            headers
        })
        return data
    }

    const {data} = await axios.get(`${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3301'}/session/url/${sessionGuid}`, {
        headers
    })
    return data


}
export const getSession = async () => {
    const session = {
        scene_guid: localStorage.getItem('scene'),
    }
    const token = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')
    const {data} = await axios.post(`${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3301'}/session/create`, session, {
        headers: {
            'Authorization': 'Bearer '+token,
            'API':1,
            'x-refresh': refreshToken,
        }
    })
   return data.session_guid
}

export const getScenes = async () => {
    const { data } = await axios.get(`${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:3301'}/scene/list`, {
        headers: {
            'API':1,
        }
    })
    return data
}
