import {
  ArrowNarrowDownIcon,
  ArrowNarrowRightIcon,
  PlusCircleIcon,
  ShareIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import React from "react";
import Container from "../container";

function FeaturesHeader({ text }: { text: string }) {
  return (
    <h2 className="mt-16 text-2xl sm:text-3xl text-gray-900 font-extrabold tracking-tight">
      {text}
    </h2>
  );
}

function FeaturesContent({ children }: { children: React.ReactElement }) {
  return <p className="mt-4 max-w-3xl space-y-6 text-gray-500">{children}</p>;
}

function FeaturesStep({ children }: { children: React.ReactElement }) {
  return (
    <div className="flex items-center justify-center space-x-2 font-medium text-indigo-500 text-xl ring ring-indigo-500 rounded-md px-2 py-2 sm:py-4 w-48 sm:w-60">
      {children}
    </div>
  );
}

function FeaturesArrow() {
  return (
    <>
      <ArrowNarrowRightIcon
        width={50}
        className="text-yellow-500 hidden sm:block"
      />
      <ArrowNarrowDownIcon width={40} className="text-yellow-500 sm:hidden" />
    </>
  );
}

export default function Features() {
  return (
    <div className="bg-white">
      <Container>
        <div className="flex flex-col sm:flex-row sm:space-x-8 space-y-4 sm:space-y-0 justify-center items-center">
          <FeaturesStep>
            <>
              <PlusCircleIcon width={24} />
              <span>Create a room</span>
            </>
          </FeaturesStep>
          <FeaturesArrow />
          <FeaturesStep>
            <>
              <ShareIcon width={24} />
              <span>Share the link</span>
            </>
          </FeaturesStep>
          <FeaturesArrow />
          <FeaturesStep>
            <>
              <VideoCameraIcon width={24} />
              <span>Chat!</span>
            </>
          </FeaturesStep>
        </div>
        <FeaturesHeader text="Peer-to-peer, end-to-end." />
        <FeaturesContent>
          <>
            Unlike most video call applications, all video data in p2p.chat is
            sent end-to-end encrypted directly to the other participants instead
            of routing the data via a server.
          </>
        </FeaturesContent>
        <FeaturesHeader text="Unique, secure rooms." />
        <FeaturesContent>
          <>
            Rooms are generated by creating a unique encrypted hash of the room
            name, making room links effectively unguessable. Rooms created with
            the same name will be treated as separate rooms, keeping you safe
            from &quot;call bombing&quot;.
          </>
        </FeaturesContent>
        <FeaturesHeader text="Privacy first." />
        <FeaturesContent>
          <>
            The only server calls p2p.chat makes are to a signalling server that
            helps you find and connect to peers. Room names, participant names
            and, most importantly, video data are never sent to p2p.chat. This
            ensures your data is secure and private at all times - if your data
            is never sent to us, there is no way we can monitor or track it.
          </>
        </FeaturesContent>
        <FeaturesHeader text="What's the catch?" />
        <FeaturesContent>
          <>
            p2p.chat uses what is referred to as a mesh architecture, where each
            participant in a call sends their video data directly to every other
            participant. The downside of this is that it requires more CPU and
            bandwidth as the numbers of users grows when compared to more
            traditional video conferencing. However, for calls with a small
            number of participants, this is not a problem.
          </>
        </FeaturesContent>
      </Container>
    </div>
  );
}
