import React, {useEffect, useState} from "react";
import {getScenes} from "../../lib/axios";
import {Checkbox, Modal, Typography} from "@mui/material";
import {Box} from "@mui/system";
import Select from "../lib/select";


const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


// TODO: don't throw rocks on me, I must complete this quick
export default function SettingsModal({ isOpenModal, setIsOpenModal }: any) {
    const [scenes, setScenes] = useState([])
    const [selectedScene, setSelectedScene] = useState(null)
    const [isLogsEnabled, setIsLogsEnabled] = useState(false)
    useEffect(() => {
        requestScenes()
        requestLogsEnabled()
    },[])

    useEffect(() => {
        if (isOpenModal) {
            requestLogsEnabled()
        }
    }, [isOpenModal])

    const requestScenes = async () => {
        const result = await getScenes()
        setScenes(result?.list)
        selectScene(result?.list)
    }

    const requestLogsEnabled = () => {
        const logsEnabled = localStorage.getItem('logs') as string
        setIsLogsEnabled(!!(+logsEnabled))
    }

    const handleLogs = (value: any) => {
        console.log('value', value)
        setIsLogsEnabled(value)
        console.log(`${(value ? 1 : 0)}`)
        localStorage.setItem('logs', `${(value ? 1 : 0)}`)
    }

    const storeScene = (data: any) => {
        localStorage.setItem('scene', data)
        selectScene(scenes)
    }

    const selectScene = (scenes: any) => {
        const storedItem = localStorage.getItem('scene')
        const item = (scenes.find((s: any) => s.guid === storedItem) || scenes[0])
        setSelectedScene({ value: item.guid, text: item.name } as any)
    }


    return (
        <Modal
            open={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Select scene
                </Typography>
                <Select
                    id="audio-select"
                    fallback="No scene selected"
                    label="Scene"
                    selectedOption={selectedScene as any}
                    options={scenes.map((s: any) => ({ value: s.guid, text: s.name}))}
                    setValue={storeScene}
                />
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Enable logs
                </Typography>
                <Checkbox
                    onChange={(e) => handleLogs(!isLogsEnabled)}
                    checked={isLogsEnabled}
                />
            </Box>
        </Modal>
    )
}
